<template>
  <div class="view-ticket__sidebar-block sidebar-block">
    <div v-if="!isEmpty(ticket.priority)" class="ticket-info-row">
      <div class="ticket-info-label">
        Приоритет
      </div>
      <div class="ticket-info-value">
        <span
          class="ticket-priority"
          :style="`background: ${ticketPriorityColor}`"
        >
          {{ ticket.priority.translatedName }}
        </span>
      </div>
    </div>
    <div v-if="!isEmpty(ticket.customer)" class="ticket-info-row">
      <div class="ticket-info-label">
        Клиент
      </div>
      <div class="ticket-info-value ticket-info__link" @click="showUserInfoModal(ticket.customer)">
        <esmp-user-avatar
          :username="ticket.customer.fullName"
          class="ticket-info-avatar"
        />
        {{ ticket.customer.fullName }}
      </div>
    </div>
    <div v-if="ticket.createBy && ticket.createBy.fullName" class="ticket-info-row">
      <div class="ticket-info-label">
        Создал
      </div>
      <div class="ticket-info-value ticket-info__link" @click="showUserInfoModal(ticket.createBy)">
        <esmp-user-avatar
          :username="ticket.createBy.fullName"
          class="ticket-info-avatar"
        />
        {{ ticket.createBy.fullName }}
      </div>
    </div>
    <div v-if="!isEmpty(ticketExecutor)" class="ticket-info-row">
      <div class="ticket-info-label">
        Исполнитель
      </div>
      <div class="ticket-info-value ticket-info__link" @click="showUserInfoModal(ticketExecutor)">
        <esmp-user-avatar
          :username="ticketExecutor.fullName"
          class="ticket-info-avatar"
        />
        {{ ticketExecutor.fullName }}
      </div>
    </div>
    <div v-if="ticket.priority && isWatcherShowed" class="ticket-info-row ticket-info-row--align-top">
      <div class="ticket-info-label">
        Наблюдатели
      </div>
      <div class="ticket-info-value ticket-info-value--column">
        <template v-if="ticket.watcherList && ticket.watcherList.length">
          <div
            v-for="watcher in ticket.watcherList"
            :key="watcher.id"
            class="ticket-watcher ticket-info__link"
            @click="showUserInfoModal(watcher)"
          >
            <esmp-user-avatar
              :username="watcher.fullName"
              class="ticket-info-avatar"
            />
            {{ watcher.fullName }}
          </div>
        </template>
        <template v-else>
          Отсутствуют
        </template>
      </div>
    </div>
    <div v-if="ticket.priority && isWatcherShowed" class="ticket-info-row">
      <div class="ticket-info-label" />
      <div class="ticket-info-value">
        <span
          v-if="showAddWatcherButton"
          class="view-ticket__add-watchers"
          @click="showWatcherModal"
        >
          <span>Добавить/Удалить наблюдателей</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'InfoBlock',
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    ticketPriorityColor: {
      type: String,
      required: false,
      default: undefined,
    },
    ticketExecutor: {
      type: Object,
      required: false,
      default: null,
    },
    showAddWatcherButton: {
      type: Boolean,
      required: false,
    },
    isWatcherShowed: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    isEmpty,
    showUserInfoModal(user) {
      this.$emit('show-user-info-modal', user);
    },
    showWatcherModal() {
      this.$emit('show-watcher-modal');
    },
  },
};
</script>

<style lang="scss">
.ticket-info-avatar {
  width: 16px;
  height: 16px;
}
</style>
